<template lang="html">
  <div>
    <div class="card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-md-2">
            <b class="required">Tiêu để</b>
          </div>
          <div class="col-md-10">
            <el-input
              placeholder="Tiêu đề"
              v-model="form.title_vi"
              :class="errors.has('title_vi')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="title_vi"
              data-vv-as="Tiêu đề"
            ></el-input>
            <span class="text-danger" v-if="errors.has('title_vi')">{{ errors.first('title_vi') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <b>Tiêu đề (en)</b>
          </div>
          <div class="col-md-10">
            <el-input
              placeholder="Tiêu đề (en)"
              v-model="form.title_en"
            ></el-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <b class="required">Đường dẫn</b>
          </div>
          <div class="col-md-10">
            <el-input
              placeholder="Nhập đường dẫn"
              v-model="form.link"
              :class="errors.has('link')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="link"
              data-vv-as="Đường dẫn"
            ></el-input>
            <span class="text-danger" v-if="errors.has('link')">{{ errors.first('link') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Input, Button, Message } from 'element-ui';
  import { mapState } from 'vuex'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      Message
    },

    computed: {
      form: {
        get() {
          return this.$store.state.menuDetail
        },
        set(value) {}
      }
    },

    mounted() {
      let id = this.$route.params.id;
      this.$store.dispatch('fetchMenuDetail', id);
      this.$store.dispatch('setPageTitle', 'Chỉnh sửa menu');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Cập nhật',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllMenus'});
      },
      save() {
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            self.$store.dispatch('updateMenu', self.form).then(res => {
              if (res.success) {
                Message({
                  message: 'Cập nhật thành công',
                  type: 'success'
                });
              } else {
                if (res.errors) {
                  for (let err in res.errors) {
                    Message({
                      message: res.errors[err][0],
                      type: 'error'
                    });
                  }
                } else {
                  Message({
                    message: res.message,
                    type: 'error'
                  });
                }
              }
            }, error => {
              console.error(error);
            });
          }
        })
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
      this.$store.state.ruleDetail = [];
    }
  }
</script>
